import classNames from "classnames";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { ThemeContext, ThemeProvider } from "styled-components";

import { useBreakpoint, useIsInEditMode } from "hooks";
import { PropsWithEpiFormContent } from "types/Props";

import {
    StepButton,
    StepButtonContent,
    FormElements,
    Step,
    StepNumber,
    NavButton,
    Buttons,
    Check,
} from "./FormStep.styled";
import FormStepProps from "./FormStepProps";

const FormStep = ({
    id,
    className,
    formItems,
    hasSteps,
    children,
    l18n,
    stepIndex,
    open,
    active,
    done,
    isLastStep,
    stepLength,
    formGuide,
    toggleStep,
    openStep,
}: FormStepProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    open = isInEditMode || open;
    active = isInEditMode || active;
    const [isOpen, setOpen] = useState<boolean>(open);
    const [isFormStep, _setIsFormStep] = useState<boolean>(
        !!(
            formItems &&
            (formItems[0]?.component === "FormStepBlock" ||
                formItems[0]?.component === "FormResultStepBlock")
        ),
    );
    const [step, setStep] = useState<PropsWithEpiFormContent>();
    const { trigger } = useFormContext();
    const themeContext = useContext(ThemeContext);

    if (
        formItems &&
        (formItems[0]?.component === "FormStepBlock" ||
            formItems[0]?.component === "FormResultStepBlock") &&
        !step
    )
        setStep(formItems.shift());

    const elementnames = formItems
        ?.map((item) => item.elementName || "")
        .filter(Boolean);
    const theme = (themeContext && themeContext.theme) || "lightgray";
    const actionTheme = (themeContext && themeContext.actionTheme) || "blue";
    const triggerOptions = {
        shouldFocus: true,
    };
    useEffect(() => {
        if (isOpen !== open) {
            setOpen(open);
        }
    }, [isOpen, open]);

    let checkIcon = "check28";
    switch (useBreakpoint()) {
        case "XL":
            checkIcon = "check70";
            break;
        case "L":
            checkIcon = "check56";
            break;
        case "M":
            checkIcon = "check56";
            break;
        default:
            checkIcon = "check42";
    }
    const classes = classNames(className, {
        "has-step": step,
        open: open,
        closed: !open,
    });

    const getNavButtonTextNext = () => {
        if (formGuide) {
            return stepIndex + 1 === stepLength - 1
                ? l18n?.showResult || "Next"
                : l18n?.nextQuestion || "Next";
        }
        return l18n?.nextStep || "Next";
    };

    const navButtonTextNext = getNavButtonTextNext();

    const childItems = <>{children}</>;

    if (hasSteps)
        return (
            <ThemeProvider
                theme={{
                    theme: theme,
                    isFormStep: isFormStep,
                    open: !isFormStep || isOpen,
                    done: done,
                    active: !isFormStep || active,
                    formGuide: formGuide,
                }}
            >
                <Step id={id} className={classes}>
                    {step && (
                        <StepButton
                            id={step.identifier}
                            onClick={async (event) => {
                                event.preventDefault();
                                if (
                                    elementnames &&
                                    (await trigger(
                                        elementnames,
                                        triggerOptions,
                                    ))
                                )
                                    toggleStep(stepIndex);
                            }}
                            disabled={!active}
                            aria-disabled={active ? "false" : "true"}
                        >
                            <StepButtonContent>
                                {step.stepNumber && (
                                    <StepNumber>{step.stepNumber}</StepNumber>
                                )}
                                {step.label}
                                {done && !formGuide && (
                                    <Check key={checkIcon} icon={checkIcon} />
                                )}
                            </StepButtonContent>
                        </StepButton>
                    )}
                    <FormElements>
                        {childItems}
                        {(stepIndex > 0 || !isLastStep) && (
                            <Buttons>
                                {stepIndex > 0 && !formGuide && (
                                    <NavButton
                                        size="large"
                                        actionTheme={actionTheme}
                                        onClick={async (event) => {
                                            event.preventDefault();
                                            if (
                                                elementnames &&
                                                (await trigger(
                                                    elementnames,
                                                    triggerOptions,
                                                ))
                                            )
                                                openStep(stepIndex - 1);
                                        }}
                                        disabled={!active}
                                        icon="upwards28"
                                    >
                                        {l18n?.prevStep || "Previous"}
                                    </NavButton>
                                )}
                                {!isLastStep && (
                                    <NavButton
                                        size="large"
                                        actionTheme={actionTheme}
                                        variant="primary"
                                        onClick={async (event) => {
                                            event.preventDefault();
                                            if (
                                                elementnames &&
                                                (await trigger(
                                                    elementnames,
                                                    triggerOptions,
                                                ))
                                            )
                                                openStep(stepIndex + 1);
                                        }}
                                        disabled={!active}
                                        icon="downwards28"
                                    >
                                        {navButtonTextNext}
                                    </NavButton>
                                )}
                            </Buttons>
                        )}
                    </FormElements>
                </Step>
            </ThemeProvider>
        );

    return <>{children}</>;
};

export default React.memo(FormStep);
